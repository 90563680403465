import { render, staticRenderFns } from "./DesktopIndexPage.vue?vue&type=template&id=52a01208&scoped=true"
import script from "./DesktopIndexPage.vue?vue&type=script&lang=js"
export * from "./DesktopIndexPage.vue?vue&type=script&lang=js"
import style0 from "./DesktopIndexPage.vue?vue&type=style&index=0&id=52a01208&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52a01208",
  null
  
)

export default component.exports