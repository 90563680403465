<style scoped>
.el-main {
  position: relative;
  padding: 0;
}
.tab-container {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  margin-top: 20px;
  padding: 0 80px;
  text-align: center;
}
</style>
<template>
  <el-container>
    <el-main>
      <Header />
      <el-tabs class="tab-container" v-model="activeName">
        <el-tab-pane
          v-for="tab in tabs"
          :key="tab.titleId"
          :label="tab.titleName"
          :name="'' + tab.titleId"
        >
          <el-container
            v-infinite-scroll="fetchDataById"
            infinite-scroll-delay="500"
            infinite-scroll-disabled="disabled"
          >
            <Video-list
              :list="list"
              @handle-click="playItem"
              @go-to-detail="goToClientPage"
            />
          </el-container>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>
</template>
<script>
import Header from "../components/Header.vue";
import VideoList from "../components/VideoList.vue";

export default {
  components: { Header, VideoList },
  data() {
    return {
      activeName: "-1",
      list: [],
      tabs: [],
      total: 0,
      page: 1,
    };
  },
  computed: {
    disabled() {
      return this.total / 20 <= this.page;
    },
  },
  watch: {
    activeName() {
      this.list = [];
      this.page = 1;
      this.total = 0;
      this.fetchDataById();
    },
  },
  mounted() {
    this.fetchNavbar();
  },
  methods: {
    async fetchNavbar() {
      const response = await fetch("/PayShuKe/SignController/getJckd");
      const jsonData = await response.json();
      this.tabs = jsonData.video_title_json;
      if (this.tabs.length !== 0) {
        // this.fetchDataById(this.tabs[0].titleId);
        this.activeName = `${this.tabs[0].titleId}`;
      }
      console.log(jsonData);
    },
    async fetchDataById() {
      if (this.total / 20 > this.page) {
        this.page += 1;
      }
      const response = await fetch(
        `/PayShuKe/SignController/getjckdnr?page=${this.page}&rows=20&type=1&title_id=${this.activeName}`
      );
      const jsonData = await response.json();
      console.log(jsonData);
      this.list = [...this.list, ...jsonData.video_json];
      this.total = jsonData.total;
    },
    playItem(item) {
      console.log(item);
      this.$router.push({
        path: "/play",
        query: { item },
      });
    },
    goToClientPage(id) {
      this.$router.push({
        path: "/client",
        query: { clientId: id },
      });
    },
  },
};
</script>
