<style scoped>
.el-main {
  padding: 0;
}
#allmap {
  height: 100%;
}
</style>
<template>
  <el-container>
    <el-main>
      <Header :need-handle-search="false" @handle-search="handleSearch" />
      <div id="allmap"></div>
    </el-main>
  </el-container>
</template>
<script>
import Header from "../components/Header.vue";

export default {
  components: { Header },
  data() {
    return {
      map: null,
    };
  },
  mounted() {
    this.initMap();
    if (this.$route.query.find) {
      this.$nextTick(() => {
        this.handleSearch("熟客");
      });
    } else {
      this.fetchMarker();
    }
  },
  methods: {
    async fetchMarker() {
      const response = await fetch(
        "/PayShuKe/MstClientController/queryListApp"
      );
      const jsonData = await response.json();
      console.log(jsonData);
      this.addMarker(jsonData);
    },
    addMarker({ data = [] }) {
      this.map.add(
        data
          .filter((item) => item.lat && item.lng)
          .map((item) => {
            const marker = new window.AMap.Marker({
              position: new window.AMap.LngLat(item.lng, item.lat),
              title: item.name,
            });
            marker.on("click", () => {
              this.showIndex = 1;
              this.selectItem = item;
              this.$router.push({
                path: "/client",
                query: { clientId: item.id },
              });
            });
            return marker;
          })
      );
    },
    async handleSearch(content) {
      if (content === "") {
        this.map.clearMap();
        this.fetchMarker();
        return;
      }
      const response = await fetch(
        `/PayShuKe/MstClientController/queryListApp?searchText=${content}`
      );
      const jsonData = await response.json();
      this.map.clearMap();
      this.addMarker(jsonData);
      console.log(jsonData);
    },
    initMap() {
      this.map = new window.AMap.Map("allmap", {
        zoom: 10,
        center: [121.517382, 31.076256],
        resizeEnable: true,
      });
      window.AMap.plugin("AMap.Geolocation", () => {
        const geolocation = new window.AMap.Geolocation({
          enableHighAccuracy: true,
          timeout: 10000,
          buttonPosition: "RB",
          buttonOffset: new window.AMap.Pixel(10, 40),
          zoomToAccuracy: true,
        });
        this.map.addControl(geolocation);
      });
    },
  },
};
</script>
