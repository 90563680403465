<style scoped>
.el-main {
  padding: 0;
  background: #3c3c3c;
  position: relative;
}
.scroll-list {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}
.desc {
  margin: 0 80px;
  position: relative;
  padding: 20px 0;
  color: #fbf4f4;
  font-size: 16px;
}
.desc::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background: #fff;
}
</style>
<template>
  <el-container>
    <el-main>
      <Header :need-handle-search="false" @handle-search="searchVideo" />
      <div class="scroll-list">
        <div class="desc">以下是{{ searchContent }}的搜索结果：</div>
        <Video-list
          :list="list"
          @handle-click="playItem"
          @go-to-detail="goToClientPage"
        />
      </div>
    </el-main>
  </el-container>
</template>
<script>
import Header from "../components/Header.vue";
import VideoList from "../components/VideoList.vue";

export default {
  components: { Header, VideoList },
  data() {
    return { searchContent: "", list: [], total: 0 };
  },
  mounted() {
    const searchContent = this.$route.query.searchContent;
    this.searchContent = searchContent;
    this.searchVideo(searchContent);
  },
  methods: {
    async searchVideo(value) {
      if (value === "") {
        this.fetchInfo();
        return;
      }
      const response = await fetch(
        `/PayShuKe/SignController/getjckdnr?type=1&searchContent=${value}`
      );
      const jsonData = await response.json();
      console.log(jsonData);
      this.total = jsonData.total;
      this.list = jsonData.video_json;
    },
    playItem(item) {
      console.log(item);
      this.$router.push({
        path: "/play",
        query: { item },
      });
    },
    goToClientPage(id) {
      this.$router.push({
        path: "/client",
        query: { clientId: id },
      });
    },
  },
};
</script>
