<style scoped>
.el-main {
  padding: 0;
}
.video-info {
  position: relative;
  padding-top: 20px;
  background: rgba(0, 0, 0, 0.5);
}
.video-container {
  display: flex;
  justify-content: center;
}
.video {
  height: calc(100vh - 80px);
}
.video-base {
  position: absolute;
  bottom: 0;
  left: 0;
  padding-left: 20px;
  padding-bottom: 20px;
  color: #f9f5f5;
}
.video-base .title {
  margin-bottom: 15px;
}
.client-info {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.comment-box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 240px;
}
.comment-box .icon {
  width: 20px;
  height: 20px;
}
.star,
.comment,
.share,
.like {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #f9f5f5;
}
.client-icon {
  height: 40px;
  width: 40px;
  margin-bottom: 30px;
  border-radius: 50%;
}
</style>
<style>
.el-popper {
  background: #050626;
  border-color: #050626;
}
.el-popper .popper__arrow::after {
  content: none !important;
  border: 0;
  margin: 0;
}
.el-popper .code-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.el-popper .code-container .label {
  color: #f9f5f5;
  background: #050626;
}
.el-popper .code-container .code-image {
  height: 200px;
  width: 200px;
  margin-top: 20px;
}
</style>
<template>
  <el-container>
    <el-main>
      <Header />
      <div class="video-info">
        <div class="video-container">
          <video
            class="video"
            controls
            muted="muted"
            controlslist="nodownload nofullscreen noremoteplayback noplaybackrate"
          >
            <source :src="video.video_url" />
            您的浏览器不支持 HTML5 video 标签。
          </video>
        </div>
        <div class="video-base">
          <div class="title">{{ video.video_title }}</div>
          <div class="client-name">@{{ video.clientName }}</div>
        </div>
        <div class="client-info">
          <el-link type="info" @click="goToClientPage">
            <img :src="video.mtzPath" class="client-icon" />
          </el-link>
          <div class="comment-box">
            <div class="like">
              <img
                src="../assets/mobile-like.png"
                class="icon"
                srcset=""
                v-if="!video.praise"
              />
              <img
                src="../assets/like-selected.png"
                class="icon"
                srcset=""
                v-else
              />
              {{ video.praiseNum || 0 }}
            </div>
            <div class="star" @click="starClick(video)">
              <img
                src="../assets/mobile-star.png"
                class="icon"
                srcset=""
                v-if="!video.collection"
              />
              <img
                src="../assets/star-selected.png"
                class="icon"
                srcset=""
                v-else
              />
              {{ video.collectionNum || 0 }}
            </div>
            <div class="comment">
              <img src="../assets/mobile-message.png" class="icon" srcset="" />
              {{ video.commentNum || 0 }}
            </div>
            <div class="share">
              <el-popover
                class="share-code-popover"
                placement="right"
                width="400"
                trigger="click"
              >
                <div class="code-container">
                  <div class="label">扫码下载APP后分享给好友</div>
                  <img src="../assets/code.jpeg" class="code-image" alt="" />
                </div>
                <img
                  src="../assets/mobile-share.png"
                  class="icon"
                  srcset=""
                  slot="reference"
                />
              </el-popover>
              {{ video.forwardnum }}
            </div>
          </div>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import Header from "../components/Header.vue";

export default {
  components: { Header },
  data() {
    return {
      video: {},
    };
  },
  mounted() {
    const item = this.$route.query.item;
    if (Object.keys(item).length === 0) {
      location.href = "/";
      return;
    }
    this.video = item;
  },
  methods: {
    goToClientPage() {
      this.$router.push({
        path: "/client",
        query: { clientId: this.video.id },
      });
    },
    async starClick(video) {
      if (!this.$root.$children[0].isLogin) {
        this.$message.error("需要登录");
        return;
      }
      const response = await fetch(
        `/PayShuKe/client_iphone_aupca/collection?id=${video.xh}&userId=${this.$root.$children[0].userInfo.xh}`
      );
      const jsonData = await response.json();

      if (jsonData.code === "0000") {
        this.video = {
          ...video,
          collectionNum: jsonData.data.num,
          collection: jsonData.data.status,
        };
      }
    },
  },
};
</script>
