<style scoped>
.header {
  background: #3c3c3c;
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 1200px;
}

.input-container {
  display: flex;
  align-items: center;
}
.input-box {
  border-radius: 10px 0 0 10px;
  background: #fff;
  color: #000;
  padding-left: 10px;
  height: 40px;
  width: 400px;
  line-height: 40px;
}
.search-button {
  width: fit-content;
  color: #999999;
  background: #fff;
  padding: 0 10px;
  border-radius: 0 10px 10px 0;
  height: 40px;
}
.button {
  margin-left: auto;
}
.button,
.button::v-deep span {
  width: 120px;
  height: 40px;
}
::v-deep .el-input__inner {
  height: 40px;
  border: 0;
}
::v-deep .el-input__inner,
::v-deep .el-input__clear {
  line-height: 40px;
}
.user-info {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.user-info .circle {
  border-radius: 50%;
  background: #fff;
  height: 30px;
  width: 30px;
  margin-right: 10px;
}
</style>
<style>
.el-dialog {
  background: #050626;
  border-radius: 20px;
  width: 600px;
  padding: 0px 10px;
}
.el-dialog .el-dialog__body {
  padding: 10px 20px;
}
.el-dialog .logo {
  height: 44px;
  text-align: center;
}
.login-tabs {
  display: flex;
  font-size: 16px;
  justify-content: space-between;
}
.login-tab .button {
  color: #7d7d7d;
}
.login-tab.active .button {
  color: #fff;
}
.el-dialog .logo img {
  height: 100%;
}
.el-dialog .el-form {
  margin-top: 20px;
}
.form-item .el-form-item__content {
  display: flex;
  background: #fff;
  border-radius: 10px;
  padding: 0 10px;
}
.form-item .el-select {
  width: 30%;
}
.code-button {
  width: fit-content;
  color: #999999;
  background: #fff;
  padding: 0 10px;
  border-radius: 0 10px 10px 0;
  height: 40px;
}
.login-button {
  width: 300px;
}
.base-login {
  width: 300px;
  position: relative;
  margin-top: 40px;
}
/*.base-login:after {*/
/*  content: "";*/
/*  position: absolute;*/
/*  top: 50px;*/
/*  right: -40px;*/
/*  bottom: 0;*/
/*  width: 1px;*/
/*  background: #a21f1f;*/
/*}*/
.scan-login {
  margin-left: 60px;
  display: flex;
  flex-direction: column;
  width: 200px;
  align-items: center;
  justify-content: space-around;
  margin-top: 40px;
}
.scan-login .label {
  font-size: 16px;
  color: #fff;
  text-align: center;
}
.scan-login .desc {
  font-size: 14px;
  text-align: center;
}
.scan-login img {
  width: 140px;
  height: 140px;
}
.login-container{
  /*display: flex;*/
  justify-content: center;
  align-items: center;
  /*height: 100%;*/
  margin-left: 120px;
}
</style>
<template>
  <el-header class="header">
    <div
      class="empty"
      style="width: 20px; height: 40px; margin-right: auto"
    ></div>
    <div class="input-container">
      <el-input
        class="input-box"
        v-model="input"
        placeholder="请输入内容"
        clearable
      ></el-input>
      <el-button
        class="search-button"
        type="text"
        icon="el-icon-search"
        @click="handleSearch"
      >
        搜索
      </el-button>
    </div>
    <el-button
      type="danger"
      class="button"
      plain
      @click="dialogFormVisible = true"
      v-if="!$root.$children[0].isLogin"
      >登录</el-button
    >
    <el-popover placement="bottom" class="user-info" v-else>
      <el-button
        size="mini"
        type="text"
        style="color: #fff"
        @click="handleLogout"
        >退出登录</el-button
      >
      <div class="user-info" slot="reference">
        <div class="circle">
          <img
            src="../assets/user-icon.png"
            width="30px"
            height="30px"
            alt=""
          />
        </div>
        <div class="name">{{ $root.$children[0].userInfo.nickname }}</div>
      </div>
    </el-popover>
    <el-dialog
      title=""
      custom-class="cus-dialog"
      :visible.sync="dialogFormVisible"
      :show-close="false"
    >
      <div class="logo">
        <img src="../assets/logo.png" alt="" />
      </div>
      <div class="login-container">
        <div class="base-login">
          <div class="login-tabs">
            <div class="login-tab" :class="{ active: isCodeLogin }">
              <el-button class="button" type="text" @click="switchToCodeLogin">验证码登录</el-button>
            </div>
            <div class="login-tab" :class="{ active: isScanLogin }">
              <el-button class="button" type="text" @click="switchToScanLogin">扫码登录</el-button>
            </div>
          </div>
          <!-- 验证码登录表单 -->
          <el-form v-if="isCodeLogin" :model="form">
            <el-form-item label="" class="form-item">
              <el-select v-model="form.country">
                <el-option label="+86" value="86"></el-option>
                <el-option label="+862" value="862"></el-option>
              </el-select>
              <el-input
                v-model="form.phone"
                placeholder="手机号"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item class="form-item">
              <el-input
                v-model="form.code"
                placeholder="请输入验证码"
              ></el-input>
              <el-button class="code-button" type="text" @click="fetchCode">{{ timeLabel }}</el-button>
            </el-form-item>
            <el-button class="login-button" type="primary" @click="login">登录</el-button>
          </el-form>


        </div>
        <div class="scan-login" v-if="isScanLogin">
<!--          <div class="label">扫码登录</div>-->
          <img :src="imgUrl" alt="" />
          <div class="desc">打开熟客APP扫一扫登录</div>
          <el-button class="login-button" type="primary" @click="reshCode" v-show="showButton">登录失败，刷新验证码</el-button>
        </div>
      </div>

      <div slot="footer" class="dialog-footer"></div>
    </el-dialog>
  </el-header>
</template>
<script>
export default {
  props: { needHandleSearch: { type: Boolean, default: true } },
  data() {
    return {
      input: "",
      dialogTableVisible: false,
      dialogFormVisible: false,
      isCodeLogin: true, // 是否为验证码登录
      isScanLogin: false, // 是否为扫码登录
      canSend: true,
      timeLabel: "发送验证码",
      form: {
        contry: "86",
        phone: "",
        code: "",
        passowrd: "",
        account: "",
      },
      imgUrl:"",
      key:"",
      showButton:false,//刷新验证码显示
      stopPolling : false
    };
  },
  mounted() {
    console.log(this.$root.$children[0].$data);
  },
  methods: {
    switchToCodeLogin() {
      this.isCodeLogin = true;
      this.isScanLogin = false;
    },
    //验证码登录，从后台获取图片
    switchToScanLogin() {
      this.isCodeLogin = false;
      this.isScanLogin = true;
      this.getLoginQrcode()

    },
    async reshCode(){
      this.imgUrl="";
      this.showButton=false;
      this.getLoginQrcode()

    },
    async getLoginQrcode() {
      if (this.imgUrl === "") {
        // 获取登录二维码
        const timestamp = Date.now().toString(); // 获取当前时间戳
        const random = Math.random().toString(36).substr(2); // 生成随机数

        const randomString = timestamp + random;
        this.key = randomString.substr(0, 20); // 截取前20位字符作为随机字符串

        try {
          const response = await fetch(
            `/PayShuKe/SignController/codeLogin?key=${this.key}`
          );

          if (response.ok) { // 确保响应成功
            const jsonData = await response.json();
            console.log(jsonData);

            if (jsonData.code == "1111") {
              this.imgUrl = jsonData.url;
              await this.startPolling();
            }
          } else {
            console.log("网络请求失败");
          }
        } catch (e) {
          console.log(e);
        }
      }


    },async startPolling() {
      // 定时轮询
      const pollInterval = 2000; // 轮询间隔为2秒
      const maxPollTime = 3 * 60 * 1000; // 最长轮询时间为3分钟
      // let stopPolling = false;
      let elapsedTime = 0;

      const poll = async () => {
        try {
          const codeResponse = await fetch(`/PayShuKe/SignController/codeLogin?key=${this.key}`);
          if(codeResponse.ok){
            const codeResult = await codeResponse.json()
            if (codeResult.code == "1111") {
              // 继续轮询
              elapsedTime += pollInterval;
              if (elapsedTime < maxPollTime) {
                setTimeout(poll, pollInterval);
              } else {
                this.imgUrl = "已过期"; // 将二维码显示为已过期
                this.showButton=true;
                this.stopPolling = true; // 停止轮询
              }
            } else if (codeResult.code === "9999") {
              // 停止轮询
              this.stopPolling = true;
              this.showButton=true;
            }else if (codeResult.code === "0000"){
              this.$root.$children[0].isLogin = codeResult.code;
              this.$root.$children[0].userInfo = codeResult.data;
              // this.dialogFormVisible = !loginSuccess;
              localStorage.setItem("isLogin", codeResult.code);
              localStorage.setItem("userInfo", JSON.stringify(codeResult.data));
              this.dialogFormVisible=false
              this.stopPolling = true;
            }
          }

        } catch (e) {
          console.log(e);
        }
      };

      // 开始轮询
      await poll();
    },
    async fetchCode() {
      if (
        this.form.phone === "" ||
        !/^1[3456789]\d{9}$/.test(this.form.phone)
      ) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      if (!this.canSend) {
        return;
      }
      try {
        const response = await fetch(
          `/PayShuKe/SignController/loginCode?phonenumber=${this.form.phone}`
        );
        const jsonData = await response.json();
        console.log(jsonData);
        this.canSend = false;
        let time = 60;
        const interval = setInterval(() => {
          if (time === 0) {
            clearInterval(interval);
            this.canSend = true;
            this.timeLabel = "发送验证码";
            return;
          }
          this.timeLabel = `${time}s`;
          time -= 1;
        }, 1000);
      } catch (e) {
        this.$message.error("发送验证码失败");
        console.log(e);
      }
    },
    async login() {
      if (
        this.form.phone === "" ||
        !/^1[3456789]\d{9}$/.test(this.form.phone)
      ) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      if (this.form.code === "") {
        this.$message.error("请输入验证码");
        return;
      }
      try {
        const response = await fetch(
          `/PayShuKe/SignController/loginByIphone?phonenumber=${this.form.phone}&verification_code=${this.form.code}`
        );
        const jsonData = await response.json();
        console.log(jsonData);
        const loginSuccess = Number(jsonData.state_code) !== 9999;
        this.$root.$children[0].isLogin = loginSuccess;
        this.$root.$children[0].userInfo = jsonData;
        this.dialogFormVisible = !loginSuccess;
        localStorage.setItem("isLogin", loginSuccess);
        localStorage.setItem("userInfo", JSON.stringify(jsonData));
      } catch (e) {
        console.log(e);
      }
    },
    handleLogout() {
      this.$root.$children[0].isLogin = false;
      localStorage.setItem("isLogin", false);
      localStorage.setItem("userInfo", JSON.stringify({}));
    },
    handleSearch() {
      if (!this.needHandleSearch) {
        this.$emit("handle-search", this.input);
        return;
      }
      if (this.input === "") {
        return;
      }
      this.$router.push({
        path: "/search",
        query: { searchContent: this.input },
      });
    },
  },
};
</script>
