<template>
  <f7-app :params="f7params">
    <f7-views tabs toolbar-through>
      <f7-view class="view">
        <f7-page>
          <a
            href="#"
            :class="[
              'tab-link nav-bar panel-open',
              showIndex === 0 ? 'no-color' : '',
            ]"
            data-panel="left"
          >
            <i class="f7-icons icons color-white">bars</i>
          </a>
          <div
            class="toolbar tabbar toolbar-top tabbar-scrollable"
            v-if="showIndex === 1"
          >
            <div class="toolbar-inner">
              <a
                href="#"
                :class="[
                  'tab-link',
                  navIndex === index ? 'tab-link-active' : '',
                ]"
                v-for="(item, index) in tabs"
                :key="item.titleId"
                @click="fetchDataById(item.titleId, index)"
              >
                {{ item.titleName }}
              </a>
            </div>
          </div>
          <div
            class="toolbar tabbar toolbar-top tabbar-scrollable"
            v-if="showIndex === 3"
          >
            <div class="toolbar-inner">
              <a
                href="#"
                :class="[
                  'tab-link',
                  myNavIndex === index ? 'tab-link-active' : '',
                ]"
                v-for="(item, index) in [
                  '浏览',
                  '收藏',
                  '点赞',
                  '商家控制台',
                  '订单',
                ]"
                :key="item"
                @click="myNavIndex = index"
              >
                {{ item }}
              </a>
            </div>
          </div>
          <div
            class="panel panel-left panel-cover panel-init dark"
            id="panel-nested"
            data-container-el="#panel-page"
          >
            <div class="page">
              <div class="logo">
                <img src="../assets/logo.png" alt="" class="logo" />
              </div>
              <div class="nav-items">
                <div
                  :class="[
                    'nav-item panel-close',
                    showIndex == 0 ? 'active' : '',
                  ]"
                  @click="switchTab(0)"
                >
                  看点
                </div>
                <div
                  :class="[
                    'nav-item panel-close',
                    showIndex == 1 ? 'active' : '',
                  ]"
                  @click="switchTab(1)"
                >
                  门店
                </div>
                <div
                  :class="[
                    'nav-item panel-close',
                    showIndex == 2 ? 'active' : '',
                  ]"
                  @click="switchTab(2)"
                >
                  地图
                </div>
                <div
                  :class="[
                    'nav-item panel-close',
                    showIndex == 3 ? 'active' : '',
                  ]"
                  @click="switchTab(3)"
                >
                  我的
                </div>
              </div>
              <div class="bottom-info">
                <div class="back-up">
                  <div class="title">
                    <img src="../assets/police.png" alt="" class="image" />
                    沪公网安备
                  </div>
                  31011202012829号
                </div>
                <div class="bottom-nav-items">
                  <div class="nav-item" @click="goToPage('/AboutPage')">
                    关于我们
                  </div>
                  <div class="nav-item" @click="goToPage('/PrivatePage')">
                    隐私政策
                  </div>
                  <div class="nav-item" @click="goToPage('/ProtocolPage')">
                    用户服务协议
                  </div>
                  <div class="nav-item" @click="goToPage('/JoinPage')">
                    加入我们
                  </div>
                  <div class="nav-item panel-close" @click="findMe">地图找到我们</div>
                  <div class="nav-item" @click="goToPage('/InforPage')">
                    公司资质
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="page-content">
            <div
              class="video-container"
              id="video-container"
              v-show="showIndex === 0"
            >
              <div
                class="swiper-slides"
                v-for="(video, index) in videos"
                :key="video.clientId"
              >
                <div class="slides">
                  <div class="video-desc">
                    <div class="client-name">@{{ video.clientName }}</div>
                    <div class="title">
                      {{ video.video_title }} #{{ video.title_name }}
                    </div>
                  </div>
                  <div class="video-box">
                    <div class="video-mask" @click="playVideo()"></div>
                    <video
                      x5-video-player-type="h5-page"
                      webkit-playsinline
                      playsinline
                      class="video slide-video"
                      controls
                      controlslist="nodownload nofullscreen noremoteplayback noplaybackrate"
                    >
                      <source :src="video.video_url" />
                      您的浏览器不支持 HTML5 video 标签。
                    </video>
                  </div>
                  <div class="client">
                    <img :src="video.mtzPath" class="client-icon" />
                    <div class="comment-box">
                      <div class="like" @click="handleIsLogin">
                        <img
                          src="../assets/mobile-like.png"
                          class="icon"
                          srcset=""
                          v-if="!video.praise"
                        />
                        <img
                          src="../assets/like-selected.png"
                          class="icon"
                          srcset=""
                          v-else
                        />
                        {{ video.praiseNum || 0 }}
                      </div>
                      <div class="star" @click="starClick(video, index)">
                        <img
                          src="../assets/mobile-star.png"
                          class="icon"
                          srcset=""
                          v-if="!video.collection"
                        />
                        <img
                          src="../assets/star-selected.png"
                          class="icon"
                          srcset=""
                          v-else
                        />
                        {{ video.collectionNum || 0 }}
                      </div>
                      <div class="comment" @click="handleIsLogin">
                        <img
                          src="../assets/mobile-message.png"
                          class="icon"
                          srcset=""
                        />
                        {{ video.commentNum || 0 }}
                      </div>
                      <div class="share">
                        <a class="popover-open" data-popover=".popover-share">
                          <img src="../assets/mobile-share.png" class="icon" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="shop-info" v-if="showIndex === 1">
              <div class="list">
                <div
                  class="other-video"
                  v-for="(video, index) in otherList"
                  :key="index"
                >
                  <div class="mask" @click="goToViewPoint(video.clientId)">
                    <div class="title">{{ video.video_title }}</div>
                  </div>
                  <video
                    x5-video-player-type="h5-page"
                    webkit-playsinline
                    playsinline
                    class="video"
                    controls
                    controlslist="nodownload nofullscreen noremoteplayback noplaybackrate"
                  >
                    <source :src="video.video_url" />
                    您的浏览器不支持 HTML5 video 标签。
                  </video>
                </div>
              </div>
            </div>
            <div id="allmap" v-show="showIndex === 2 || showIndex === 4"></div>
            <div class="my" v-show="showIndex === 3">
              <div class="list" v-if="myNavIndex < 3">
                <div
                  class="other-video"
                  v-for="(video, index) in list"
                  :key="index"
                >
                  <div class="mask" @click="goToViewPoint(video.clientId)">
                    <div class="title">{{ video.video_title }}</div>
                  </div>
                  <video
                    x5-video-player-type="h5-page"
                    webkit-playsinline
                    playsinline
                    class="video"
                    controls
                    controlslist="nodownload nofullscreen noremoteplayback noplaybackrate"
                  >
                    <source :src="video.video_url" />
                    您的浏览器不支持 HTML5 video 标签。
                  </video>
                </div>
              </div>
              <div class="other" v-else>暂不支持查看，请去APP查看</div>
            </div>
          </div>
          <div class="popover popover-share">
            <div class="popover-angle"></div>
            <div class="popover-inner">
              <div class="code-container">
                <div class="label">扫码下载APP后分享给好友</div>
                <img src="../assets/code.jpeg" class="code-image" alt="" />
              </div>
            </div>
          </div>
        </f7-page>
        <div class="login-dialog" v-show="showLogin">
          <div class="login-dialog-inner">
            <div class="dialog-header">
              <div class="close-icon" @click="showLogin = false">
                <i class="f7-icons">multiply</i>
              </div>
              <div class="title">用户登录</div>
              <div class="empty"></div>
            </div>
            <div class="dialog-body">
              <div class="login-icon">
                <img src="../assets/login-logo.png" class="image" alt="" />
              </div>
              <div class="list inline-labels">
                <ul>
                  <li class="item-content item-input">
                    <div class="item-inner">
                      <div class="item-input-wrap">
                        <input
                          type="text"
                          name="phone"
                          v-model="phone"
                          placeholder="请输入手机号"
                        />
                      </div>
                    </div>
                  </li>
                  <li class="item-content item-input">
                    <div class="item-inner">
                      <div class="item-input-wrap">
                        <input
                          type="text"
                          name="code"
                          placeholder="验证码"
                          v-model="code"
                        />
                      </div>
                      <div class="item-after">
                        <div class="button" @click="fetchCode">
                          {{ timeLabel }}
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="agree-container">
                <label class="checkbox"
                  ><input type="checkbox" v-model="isCheck" /><i
                    class="icon-checkbox"
                  ></i
                ></label>
                同意
                <div class="label">用户协议</div>
                和
                <div class="label">隐私政策</div>
              </div>
              <div class="button button-fill" @click="login">登录</div>
            </div>
            <div class="dialog-footer">
              <div class="others">其他方式登录</div>
              <img src="../assets/wechat.png" class="image" alt="" />
            </div>
          </div>
        </div>
      </f7-view>
    </f7-views>
  </f7-app>
</template>

<script>
import "framework7/css/framework7.bundle.css";
import "framework7-icons";

import ProtocolPage from "./ProtocolPage.vue";
import InforPage from "./InforPage.vue";
import AboutPage from "./AboutPage.vue";
import JoinPage from "./JoinPage.vue";
import PrivatePage from "./PrivatePage.vue";
export default {
  name: "App",
  components: {},
  data() {
    return {
      showLogin: false,
      showIndex: 0,
      navIndex: -1,
      myNavIndex: -1,
      map: null,
      phone: "",
      code: "",
      selectItem: {},
      timeLabel: "发送验证码",
      isCheck: false,
      canSend: true,
      videos: [],
      otherList: [],
      list: [],
      tabs: [],
      page: 1,
      total: 0,
      f7params: {
        theme: "ios",
        routes: [
          {
            path: "/ProtocolPage",
            component: ProtocolPage,
          },
          {
            path: "/InforPage",
            component: InforPage,
          },
          {
            path: "/AboutPage",
            component: AboutPage,
          },
          {
            path: "/JoinPage",
            component: JoinPage,
          },
          {
            path: "/PrivatePage",
            component: PrivatePage,
          },
        ],
      },
    };
  },
  watch: {
    showIndex(index) {
      if (index !== 3) {
        this.myNavIndex = -1;
      } else {
        this.myNavIndex = 0;
      }
      if (this.videos.length !== 0) {
        this.$nextTick(() => {
          this.setListener();
        });
      }
      if (index == 2) {
        this.fetchMarker();
      }
    },
    videos(newVal) {
      if (newVal !== 0) {
        this.$nextTick(() => {
          this.setListener();
        });
      }
    },
    myNavIndex(newVal) {
      if (Number(newVal) < 3 && Number(newVal) >= 0) {
        if (!this.handleIsLogin()) {
          return;
        }
        this.fetchMyVideo();
      }
    },
  },
  mounted() {
    this.initMap();
    this.fetchInfo();
    this.fetchNavbar();
    // this.fetchMarker();
  },
  methods: {
    setListener() {
      const that = this;
      const videos = document.querySelectorAll(".swiper-slides");
      let startY, endY;
      let currentIndex = 0;
      function handleTouchStart(event) {
        startY = event.touches[0].pageY;
      }
      function handleTouchMove(event) {
        endY = event.touches[0].pageY;
      }
      function handleTouchEnd() {
        if (that.showIndex !== 0) {
          return;
        }
        const distance = endY - startY;
        if (distance > 160 && currentIndex > 0) {
          videos[currentIndex].classList.remove("active");
          videos[currentIndex - 1].classList.add("active");
          videos[currentIndex - 1].classList.remove("prev");
          videos[currentIndex].classList.add("next");
          currentIndex--;
        } else if (distance < -160 && currentIndex < videos.length - 1) {
          videos[currentIndex].classList.remove("active");
          videos[currentIndex + 1].classList.add("active");
          videos[currentIndex + 1].classList.remove("next");
          videos[currentIndex].classList.add("prev");
          currentIndex++;
        }
      }
      videos[0].classList.add("active");
      videos[1].classList.add("next");
      document.addEventListener("touchstart", handleTouchStart);
      document.addEventListener("touchmove", handleTouchMove);
      document.addEventListener("touchend", handleTouchEnd);
    },
    playVideo() {
      const videos = document.querySelectorAll(".swiper-slides.active .video");
      videos.forEach((item) => {
        if (item.paused) {
          item.play();
        } else {
          item.pause();
        }
      });
      this.stopVideo();
    },
    stopVideo() {
      const videos = document.querySelectorAll(".swiper-slides.prev .video");
      videos.forEach((item) => {
        if (!item.paused) {
          item.pause();
        }
      });
    },
    switchTab(index) {
      if (index === 0) {
        this.fetchInfo();
      }
      if (index === 1) {
        this.fetchDataById();
      }
      this.showIndex = index;
    },
    async fetchMarker() {
      const response = await fetch(
        "/PayShuKe/MstClientController/queryListApp"
      );
      const jsonData = await response.json();
      this.addMarker(jsonData);
      console.log(jsonData);
    },
    goToViewPoint(clientId) {
      this.fetchInfo(clientId);
      this.showIndex = 0;
    },
    async fetchCode() {
      if (this.phone === "" || !/^1[3456789]\d{9}$/.test(this.phone)) {
        this.$f7.dialog.alert("请输入正确的手机号", "");
        return;
      }
      if (!this.canSend) {
        return;
      }
      try {
        const response = await fetch(
          `/PayShuKe/SignController/loginCode?phonenumber=${this.phone}`
        );
        const jsonData = await response.json();
        if (jsonData.state_code === "9999") {
          this.$f7.dialog.alert("发送验证码失败", "");
          return;
        }
        this.canSend = false;
        console.log(jsonData);
        let time = 60;
        const interval = setInterval(() => {
          if (time === 0) {
            clearInterval(interval);
            this.canSend = true;
            this.timeLabel = "发送验证码";
            return;
          }
          this.timeLabel = `${time}s`;
          time -= 1;
        }, 1000);
      } catch (e) {
        this.$f7.dialog.alert("发送验证码失败", "");
        console.log(e);
      }
    },
    async login() {
      if (this.phone === "" || !/^1[3456789]\d{9}$/.test(this.phone)) {
        this.$f7.dialog.alert("请输入正确的手机号", "");
        return;
      }
      if (this.code === "") {
        this.$f7.dialog.alert("请输入验证码", "");
        return;
      }
      if (!this.isCheck) {
        this.$f7.dialog.alert("请同意用户协议", "");
        return;
      }
      try {
        const response = await fetch(
          `/PayShuKe/SignController/loginByIphone?phonenumber=${this.phone}&verification_code=${this.code}`
        );
        const jsonData = await response.json();
        console.log(jsonData);
        this.$root.$children[0].isLogin = Number(jsonData.state_code) !== 9999;
        this.$root.$children[0].userInfo = jsonData;
        localStorage.setItem("isLogin", Number(jsonData.state_code) !== 9999);
        localStorage.setItem("userInfo", JSON.stringify(jsonData));
        this.showLogin = false;
      } catch (e) {
        console.log(e);
      }
    },
    async fetchDataById(titleId, index) {
      this.navIndex = index;
      const response = await fetch(
        `/PayShuKe/SignController/getjckdnr?type=1&title_id=${titleId}`
      );
      const jsonData = await response.json();
      console.log(jsonData);
      this.otherList = jsonData.video_json;
    },
    async fetchDataByMarker(id) {
      const response = await fetch(
        `/PayShuKe/SignController/getUserArticleList?type=1&clientId=${id}`
      );
      const jsonData = await response.json();
      console.log(jsonData);
      this.otherList = jsonData.data.video_json;
    },
    async fetchNavbar() {
      const response = await fetch("/PayShuKe/SignController/getJckd");
      const jsonData = await response.json();
      this.tabs = jsonData.video_title_json;
      console.log(jsonData);
    },
    goToPage(pageName) {
      console.log(this.$f7.views.current.router);
      this.$f7.views.current.router.navigate({ url: pageName });
    },
    async fetchInfo(clientId) {
      const response = await fetch(
        `/PayShuKe/SignController/getjckdnr?type=1${
          clientId ? `&clientId=${clientId}` : ""
        }`
      );
      const jsonData = await response.json();
      console.log(jsonData);
      this.videos = jsonData.video_json;
    },
    initMap() {
      this.map = new window.AMap.Map("allmap", {
        zoom: 10,
        center: [121.517382, 31.076256],
        resizeEnable: true,
      });
      window.AMap.plugin("AMap.Geolocation", () => {
        const geolocation = new window.AMap.Geolocation({
          enableHighAccuracy: true,
          timeout: 10000,
          buttonPosition: "RB",
          buttonOffset: new window.AMap.Pixel(10, 110),
          zoomToAccuracy: true,
        });
        this.map.addControl(geolocation);
      });
    },
    handleIsLogin() {
      if (this.$root.$children[0].isLogin) {
        return true;
      }
      this.showLogin = true;
      return false;
    },
    async starClick(video, index) {
      if (!this.handleIsLogin()) {
        return;
      }
      const response = await fetch(
        `/PayShuKe/client_iphone_aupca/collection?id=${video.xh}&userId=${this.$root.$children[0].userInfo.xh}`
      );
      const jsonData = await response.json();
      console.log(jsonData);
      if (jsonData.code === "0000") {
        this.videos[index] = {
          ...video,
          collectionNum: jsonData.data.num,
          collection: jsonData.data.status,
        };
      }
    },
    async fetchMyVideo() {
      if (this.total / 50 > this.page) {
        this.page += 1;
      }
      const formData = new FormData();
      formData.append(
        "title_id",
        { 0: 1000, 1: 1001, 2: 1002 }[Number(this.myNavIndex)]
      );
      formData.append("userId", this.$root.$children[0].userInfo.xh);
      // formData.append("userId", 110);
      // formData.append("title_id", 17);
      formData.append("page", this.page);
      formData.append("rows", 50);
      formData.append("type", 1);
      const response = await fetch("SignController/getjckdnr", {
        method: "post",
        body: formData,
      });
      const jsonData = await response.json();
      console.log(jsonData);
      this.list = [...this.list, ...jsonData.video_json];
      this.total = jsonData.total || 0;
    },
    findMe() {
      this.showIndex = 4;
      const marker = new window.AMap.Marker({
        position: new window.AMap.LngLat(121.517382, 31.076256),
        title: "上海红增信息技术有限公司",
      });
      marker.setLabel({
        direction: "top",
        offset: new window.AMap.Pixel(0, 0),
        content: "上海红增信息技术有限公司",
      });
      this.map.add(marker);
    },
    addMarker({ data = [] }) {
      this.map.add(
        data
          .filter((item) => item.lat && item.lng)
          .map((item) => {
            const marker = new window.AMap.Marker({
              position: new window.AMap.LngLat(item.lng, item.lat),
              title: item.name,
            });
            marker.on("click", () => {
              this.showIndex = 1;
              this.selectItem = item;
              this.fetchDataByMarker(item.id);
              console.log(item);
            });
            return marker;
          })
      );
    },
    async handleSearch(content) {
      if (content === "") {
        this.map.clearMap();
        this.fetchMarker();
        return;
      }
      const response = await fetch(
        `/PayShuKe/MstClientController/queryListApp?searchText=${content}`
      );
      const jsonData = await response.json();
      this.map.clearMap();
      this.addMarker(jsonData);
      console.log(jsonData);
    },
  },
};
</script>

<style scoped>
#allmap {
  height: 100vh;
}
.logo {
  width: 57px;
  margin: 10px auto;
}
.panel-left {
  width: 122px;
}
.panel-left .page {
  background: #292828;
}
.page .nav-items,
.page .bottom-nav-items {
  color: #fff;
  text-align: center;
  font-size: 15px;
}
.nav-items .nav-item {
  margin: 10px 0;
  padding: 5px 0;
}
.nav-item.active {
  background: #a6cdfe;
}
.bottom-info {
  position: fixed;
  bottom: 0;
  margin-bottom: 10px;
  left: 0;
  right: 0;
}
.back-up {
  font-size: 10px;
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
}
.swiper-wrapper {
  overflow: auto;
}

.slides {
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
}
.video-box {
  width: 100%;
  height: 100%;
  position: relative;
}
.video-box .video-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 90px;
  z-index: 1;
}
.video-desc {
  position: absolute;
  bottom: 90px;
  left: 0;
  padding: 20px 10px;
  z-index: 1;
  color: #fff;
}
.video-desc .client-name {
  font-size: 18px;
  margin-bottom: 10px;
}
.video-desc .title {
  width: 60%;
}
.other-video {
  width: 100%;
  height: 200px;
  position: relative;
  margin-bottom: 10px;
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 200px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9;
}
.mask .title {
  color: #fff;
  font-size: 16px;
  padding: 10px 15px;
}
.other-video .video {
  height: 200px;
  width: 100%;
}

.back-up .title {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 14px;
  margin-bottom: 10px;
}

.bottom-nav-items .nav-item {
  margin: 8px 0;
}
.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.video {
  width: 100%;
  height: 100%;
}
.video-container .swiper-slides {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: translateY(100%);
  background: #292828;
}

.video-container .swiper-slides.active {
  transform: translateY(0);
}

.video-container .swiper-slides.next {
  transform: translateY(100%);
}

.video-container .swiper-slides.prev {
  transform: translateY(-100%);
}

.toolbar {
  height: 60px;
  line-height: 60px;
  position: fixed;
}
.toolbar .toolbar-inner {
  padding-left: 44px;
}
.nav-bar {
  padding: var(--f7-safe-area-top)
    calc(var(--f7-navbar-inner-padding-right) + var(--f7-safe-area-right)) 0
    calc(var(--f7-navbar-inner-padding-left) + var(--f7-safe-area-left));
  position: fixed;
  top: var(--f7-safe-area-top);
  z-index: 999;
  height: 59px;
  line-height: 59px;
  background: #f4f4f4;
}
.nav-bar.no-color {
  background: transparent;
}
.my {
  padding: 30px 0;
}
.client {
  position: absolute;
  left: 85vw;
  bottom: 150px;
  z-index: 1;
}
.comment-box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 200px;
}
.comment-box .icon {
  width: 30px;
  height: 30px;
}
.client-icon {
  height: 40px;
  width: 40px;
  margin-bottom: 15px;
  border-radius: 50%;
}
.login-dialog {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.3);
}
.login-dialog-inner {
  background: #fff;
  border-radius: 14px;
  width: 260px;
  height: 350px;
  padding: 0 10px;
  margin: calc((100vh - 350px) / 2) calc((100vw - 260px) / 2);
}
.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 16px;
}
.close-icon .f7-icons {
  font-size: 20px;
}
.empty {
  width: 10px;
}
.login-icon {
  text-align: center;
}
.login-icon .image {
  width: 30px;
  height: 30px;
}
.login-dialog-inner .list {
  margin-bottom: 10px;
  margin-top: 10px;
}
.login-dialog-inner .list ul:before,
.login-dialog-inner .list ul:after {
  content: none;
}
.login-dialog-inner .list .button {
  color: #f60d0d;
}
.agree-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 13px;
}
.agree-container .label {
  color: #f60d0d;
}
.agree-container .icon-checkbox {
  border-radius: 3px;
  height: 14px;
  width: 14px;
  margin-right: 5px;
}
.login-dialog-inner .button-fill {
  margin-top: 20px;
  background: #eb3b3b;
}
.dialog-footer {
  text-align: center;
}
.dialog-footer .others {
  position: relative;
  margin-top: 15px;
}
.dialog-footer .others::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 10px;
  width: 25%;
  height: 1px;
  background: #707070;
}
.dialog-footer .others::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  width: 25%;
  height: 1px;
  background: #707070;
}
.dialog-footer .image {
  width: 36px;
  height: 36px;
  margin-top: 10px;
}
.like,
.star,
.comment,
.share {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #f9f9f9;
}
.code-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.code-container .code-image {
  height: 200px;
  width: 200px;
  margin-top: 20px;
}
.ios .icon-checkbox:after,
.ios .checkbox i:after {
  font-size: 14px;
  height: 14px;
  width: 14px;
  line-height: 14px;
}
</style>
